import { HandbookDataRow } from '@emrm/handbooks/types';
import { City } from '@emrm/common/types/api';

import { ModificationTableManualBlock } from './modification-table-manual-block.type';
import { ModificationTableAutoBlock } from './modification-table-auto-block.type';
import { ModificationTableBlockedCells } from './modification-table-blocked-cells.type';

export interface ModificationTable {
	_id: string;
	city: City;
	month: HandbookDataRow;
	year: HandbookDataRow;
	blocking: ModificationTableManualBlock;
	createdAt: string;
	metadata: ModificationTableMetadata[];
	data: ModificationTableRow[];
	auto_block: ModificationTableAutoBlock;
	blocked_cells: ModificationTableBlockedCells;
}

export interface ModificationTableMetadata {
	_id: string;
	sort: number;
	name: string;
	rus_name: string;
	table_id: string;
	handbook_code: string;
	handbook_column_name: string;
	handbook_filter_name: string;
	type_column: {
		_id: string;
		name: ModificationTableCellType;
	};
}

export interface ModificationTableRow {
	_id: string;
	sort: number;
	table_id: string;
	parent_row_id: string;
	depth_level: number;
	cells: ModificationTableCell[];
}

export interface ModificationTableCell {
	_id: string;
	sort: number;
	row_id: string;
	metadata_id: string;
	access: boolean;
	value: ModificationTableCellValue;
}

export const ModificationTableCellType = {
	Handbook: 'handbook',
	String: 'string',
	Number: 'int',
	File: 'file',
	User: 'user',
} as const;

export type ModificationTableCellType =
	(typeof ModificationTableCellType)[keyof typeof ModificationTableCellType];

export type ModificationTableCellValue =
	| HandbookCellValue
	| UserCellValue
	| DocumentCellValue[]
	| string
	| number;

export type HandbookCellValue = { id: string; value: string };
export type UserCellValue = { id: number; value: string };
export type DocumentCellValue = { name: string; key: string };
